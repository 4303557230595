import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import ErrorMessage from "@/components/ErrorMessage";
import CustomErrorMessage from "@/components/CustomErrorMessage";
import ProfileHeader from "@/components/common/ProfileHeader";
import { CommonHeaderMenuItem, ThemeVariant } from "@/types/navigation";
import isNil from "lodash/isNil";
import { useLogout } from "@/hooks/useLogout";
import usePosthogTracking from "@/hooks/usePosthogTracking";
import { getQueryClient } from "@/api";
import { contract } from "contract";
import { Box, Burger, Drawer, Image, Group, Stack } from "@mantine/core";
import DropdownMenu from "@/components/header/landingPage/DropDownMenu";

const Header = () => {
  const [dropdown, setDropdown] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [signUpdropdown, setSignUpDropdown] = useState(false);
  const [loginDropdown, setLoginDropdown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const router = useRouter();
  const path = router.asPath;

  const { onLogout } = useLogout({
    onSuccessCallbackFunction: () => {
      setIsLoggedIn(false);
    },
  });

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const signupMenuItems = [
    {
      link: "/register",
      text: "User",
    },
    {
      link: "/register/wedding-planner",
      text: "Wedding Planner",
    },
    {
      link: "/register/vendor",
      text: "Vendor",
    },
  ];

  const loginMenuItems = [
    {
      link: "/login",
      text: "User",
    },
    { link: "/login/wedding-planner", text: "Wedding Planner" },
    { link: "/login/vendor", text: "Vendor" },
  ];

  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      setIsLoggedIn(true);
      return;
    }
    setIsLoggedIn(false);
  }, [path]);

  const toggleDropdown = () => setDropdown(!dropdown);

  const handleNavigation = (link: string) => {
    // if (isLoggedIn) {
      router.push(link);
    // } else {
    //   router.push("/login");
    // }
  };

  return (
    <header id="main-header" className="main-header">
      <nav className="navbar navbar-expand-lg">
        <div className="container-xl nav-container-dt justify-content-start">
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img src="/images/logo.svg" alt="logo" />
          </a>
          {/* Hamburger button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main-navigation"
            onClick={toggleMobileMenu}
          >
            <i></i><i></i><i></i>
          </button>

          <div className={`collapse navbar-collapse ${mobileMenuOpen ? "show" : ""}`} id="main-navigation">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-4">
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/festivals')}>Festivals</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/wedding')}>Weddings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/festivals')}>Events</a>
              </li>
            </ul>
          </div>

          {/* Authentication Buttons */}
          <div className="">
            {isLoggedIn ? (
              <ProfileHeaderWithLoading
                onLogout={onLogout}
                isLoggedIn={isLoggedIn}
              />
            ) : (
              <Group>
                <DropdownMenu cutomClassForButton="sg_up" buttonText="Sign up" menuItems={signupMenuItems} />
                <DropdownMenu cutomClassForButton="lg_in" buttonText="Login" menuItems={loginMenuItems} />
              </Group>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

const ProfileHeaderWithLoading = ({
  onLogout,
  isLoggedIn,
}: {
  onLogout: () => void;
  isLoggedIn: boolean;
}) => {
  const { data, isLoading, error } = getQueryClient().user.getProfile.useQuery(
    [contract.user.getProfile.path],
    {}
  );

  usePosthogTracking({
    isLoggedIn,
    userDetails:
      isLoading || !isNil(error) || !data || data.status !== 200
        ? null
        : data.body,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage error={error} h="100%" />;
  }

  if (data?.status !== 200) {
    return <CustomErrorMessage errorMessage="User data not available" />;
  }

  return (
    <ProfileHeader
      onLogout={onLogout}
      themeVariant={ThemeVariant.LIGHT}
      userDetails={data.body}
      isLoggedIn={isLoggedIn}
    />
  );
};

export default Header;
